@import '../../variables';

.layout {
  .main {
    overflow: auto;
  }
  .header {
    z-index: 1;
    .AppMainMenu-Logo {
      display: none;
    }
  }
  .page {
    height: auto;
    position: relative;
    padding: 16px;
    padding-top: 32px;
    box-sizing: border-box;
    &__content {
      border: 1px solid $light-grey-color;
      box-shadow: $block-shadow;
      position: relative;
      background-color: #fff;
      padding: 20px;
      box-sizing: border-box;
    }
    @media screen and (max-height: 800px) {
      padding-bottom: 100px;
    }
  }
}
