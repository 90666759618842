.assigned-user {
  display: flex;
  align-items: center;
  &-name {
    flex-shrink: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-icon {
    flex-shrink: 0;
    svg {
      margin-left: 10px;
    }
  }
}

.date-finish {
  color: #7a819b;
}

.asyncLookupInput {
  :global(.Mui-selected) {
    background: #67ac5c;
  }
}
