.Toastify {
  .Toastify__toast-container--bottom-left {
    bottom: 22px;
    left: 24px;
  }
  .Toastify__toast-container {
    padding: 0;
    .Toastify__toast {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 12px;
      width: 100%;
      border-radius: 8px;
      height: max-content;
      color: #fff;
      min-height: 54px;
      margin-bottom: 4px;

      .Toastify__close-button {
        color: #fff;
        opacity: 1;
        align-self: center;
      }
    }
    .error-toast-container {
      background: #f34747;
    }

    .success-toast-container {
      background: #555d67;
    }

    .Toastify__toast-body {
      padding: 0;
      margin: 0;
      max-width: 282px;
    }

    .Toast-content {
      box-sizing: border-box;
      height: max-content;
      color: #fff;
      font-size: 14px;
      padding: 0;
      margin: 0;
      word-wrap: break-word;
      overflow-wrap: break-word;
      width: 100%;
    }
  }
}
