@import '../../variables';

.errorWrapper {
  border: 1px solid $light-grey-color;
  box-shadow: $block-shadow;
  position: relative;
  background: #f3f6f9;
  min-height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  color: #63666a;
  .line {
    left: 0;
    right: 0;
    border: 1px dashed #8da8c9;
    position: absolute;
    margin-top: -127px;
  }
  .stroke {
    width: 612px;
    border: 2px dashed #8da8c9;
    border-radius: 20px;
    padding: 48px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f3f6f9;
    z-index: 1;
  }
  h2 {
    font-family: Teko, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 200px;
    line-height: 210px;
    color: #8da8c9;
    margin: 0;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    margin: 0;
    color: #8da8c9;
  }
  p {
    margin: 32px 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #181f38;
    max-width: 616px;
    text-align: center;
  }
  .transfer {
    background: #67ac5c;
    color: #ffffff;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 16px 24px;
    text-decoration: none;
    margin-top: 32px;
  }
}
