.container {
  &:global(.MuiChip-root.MuiChip-filled) {
    color: #4b5168;
    padding: 8px 12px;
    height: auto;
    border-radius: 100px;
    &:global(.MuiChip-filledError) {
      background-color: #ffe4e4;
    }
    &:global(.MuiChip-filledDefault) {
      background-color: #dce6f0;
    }
    &:global(.MuiChip-filledSuccess) {
      background-color: #cbffdd;
    }
    &:global(.MuiChip-filledInfo) {
      background-color: #ffebcc;
    }
    &:global(.MuiChip-filledWarning) {
      background-color: #dce6f0;
    }
    :global(.MuiChip-label) {
      font-size: 14px;
      line-height: 20px;
    }
    :global(.MuiChip-icon) {
      margin: 0;
      flex-shrink: 0;
      margin-right: 4px;
    }
  }
}
