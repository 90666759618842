@font-face {
  font-family: 'Calibri';
  src: url('./assets/font/Calibri.ttf') format('woff2'),
    url('./assets/font/Calibri.ttf') format('ttf');
}

@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/font/Teko/Teko-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/font/Teko/Teko-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/font/Teko/Teko-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/font/Teko/Teko-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Teko';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/font/Teko/Teko-SemiBold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f6f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.spinnerWrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datePickerField {
  margin-top: 16px;
  label {
    transform: translate3d(16px, 8px, 0) scale(0.85714) !important;
  }
}

.layout .DatePickerRoot .react-datepicker-popper {
  z-index: 4;
}
.uploadBtn {
  & > span {
    width: 50px;
    height: 48px !important;
    padding: 0;
  }
}

.fileLinkWrap {
  width: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.fieldArray {
  &__actions {
    padding-top: 15px;
  }
  &__deviceActions {
    padding-top: 20px !important;
  }
  button {
    padding-left: 10px !important;
    padding-right: 10px !important;
    height: 48px !important;
  }
}

.infoItem {
  margin: 10px 0 16px 5px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #181f38;
}

.hiddenHeaderTable {
  div[role='table'] > div:nth-child(1) {
    display: none;
  }
}

.pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.productViewTable {
  margin-left: -30px;
  margin-top: -30px;
  margin-right: -30px;
  h2 {
    color: #63666a;
    font-size: 20px;
    text-align: center;
    margin-top: 50px;
  }
}

.flexLine {
  display: flex;
  align-items: center;
}

.uploadBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selectField {
  margin-top: 16px;
  label {
    transform: translate3d(16px, 8px, 0) scale(0.85714) !important;
  }
}

.spTable {
  div[role='rowgroup'] {
    height: calc(100vh - 420px) !important;
  }
}

.serviceProvider button {
  margin-top: 15px;
  height: 49px !important;
}

.invalidPrefix > div:before {
  background: red !important;
}

.langs {
  display: flex;
  div {
    cursor: pointer;
    margin: 5px;
  }
}

.productRejectReason {
  color: red;
}

.activeBell {
  position: relative;
  &_circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    position: absolute;
    right: 2px;
    bottom: 2px;
    font-size: 9px;
    text-align: center;
    font-weight: 700;
  }
}

.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  &_triangle {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    top: 25px;
    z-index: -1;
    right: 13px;
    border-style: solid;
    border-width: 0 7.5px 10px 7.5px;
    border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
  }
  &_message {
    opacity: 0;
    position: absolute;
    top: 35px;
    background: rgba(0, 0, 0, 0.7);
    z-index: -1;
    min-width: 150px;
    right: -10px;
    color: white;
    padding: 10px;
  }
  &:hover {
    .tooltip_triangle,
    .tooltip_message {
      opacity: 1;
      z-index: 1;
    }
  }
}

.childLink {
  color: #00b5e2;
  font-weight: 600;
  cursor: pointer;
  svg {
    color: red;
  }
}

.cell_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex {
  display: flex;
}

.MuiTableHead-root {
  padding-left: 0 !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  .MuiBox-root {
    padding-left: 0;
  }
}

@-moz-document url-prefix() {
  .ConfigForm-ColumnList {
    padding-right: 20px;
  }
}
